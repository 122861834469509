div.create h1 {
  color: #555555;
}

form.recipeForm {
  margin-top: 3em;
}

.inputRow {
  display: flex;
  align-items: center;
}

.inputRow input {
  width: inherit;
  flex-grow: 1;
}

.buttonGroup {
  width: 50px;
  margin-left: 5px;
  line-height: 0;
}

.buttonGroup span {
  cursor: pointer;
}