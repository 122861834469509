@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600";

body {
  background: #fff;
  color: #555555;
  font-family: "Roboto", sans-serif;
}

/* https://fonts.google.com/icons */
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

#banner {
  text-align: center;
}

#banner::before {
  content: "";
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 35%,
    rgba(50, 50, 50, 0.6) 75%,
    rgba(50, 50, 50, 0.9) 90%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

h1 {
  text-align: center;
  color: white;
  font-size: 3.5em;
  font-weight: 400;
  z-index: 100;
  position: relative;
}

h3 {
  font-size: 2em;
  font-weight: 200;
}

p {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
}

/* Type */
body,
input,
select,
textarea {
  color: #333333;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-size: 14pt;
  font-weight: 400;
  line-height: 1.65;
}

/* Section/Article */
section.wrapper,
article.wrapper {
  padding: 6em 0;
}

/* Main */
main {
  position: relative;
  top: -3em;
  max-width: 75em;
  margin: 0 auto;
  padding: 0 3em 6em 3em;
}

main > blockquote::before {
  content: "“";
  color: #555555;
  font-size: 8em;
  position: absolute;
  top: -0.8em;
  left: -0.3em;
  opacity: 0.6;
}

main blockquote {
  display: inline-block;
  margin: 0 auto 4em auto;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 480px) {
  main {
    padding: 0 1.5em 6em 1.5em;
  }

  main blockquote {
    padding-bottom: 3em;
  }
}

/* Form */
label {
  display: block;
  margin: 0 0 1em 0;
  cursor: pointer;
}

label > input[type="checkbox"] {
  margin-right: 0.5em;
}

input[type="checkbox"]:checked + span {
  text-decoration: line-through;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: #eee;
  border-radius: 5px;
  border: none;
  border: solid 1px #555;
  display: block;
  outline: 0;
  padding: 0 0.5em;
  text-decoration: none;
  width: 100%;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
  margin-bottom: 1em;
}

/* Flex */

.flex {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.flex.flex-2 {
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}

.flex.flex-2 article {
  width: 50%;
}

@media screen and (max-width: 980px) {
  .flex.flex-2 article {
    width: 100%;
    margin-bottom: 3em;
  }

  .flex.flex-2 article:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .flex.flex-2 br {
    display: none;
  }
}
