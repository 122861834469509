div.recipe > header {
    height: 30vh;
}

div.searchBar {
    display: flex;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    background: #fff;
    max-width: 600px;
    margin: 0 auto;
    padding: 8px;
}

div.searchBar .searchIcon {
    position: relative;
    top: 5px;
}

div.searchBar input {
    background: none;
    border: none;
}

table.searchResults {
    margin: 50px auto 0;
}

table.searchResults th {
    font-weight: bold;
}

table.searchResults th, table.searchResults td {
    border: 1px solid;
    padding: 5px;
}

table.searchResults td a.description {
    color: #333333;
    text-decoration: none;
}