div.recipe > header {
    height: 80vh;
    background: url('../../images/waffle-1600.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    vertical-align: top;
  }

div.servings {
    font-size: 1.5em;
    font-weight: 200;
    text-align: center;
    margin-bottom: 0.75em;
  }

div.servings > input {
    width: 2.5em;
}